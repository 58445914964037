import React, { useState } from 'react';

//importar archivo de idiomas
import LangEspañol from './../lenguaje/es-MX.json';
import LangIngles from 'lenguaje/en-US.json';
//IntlProvider
import { IntlProvider } from 'react-intl';

const langContext = React.createContext();

const LangProvider = ({ children }) => {
  const [mensajes, setMensajes] = useState(LangIngles);
  const [locale, setLocale] = useState('en-US');

  const establecerLenguaje = (lenguaje) => {
    switch (lenguaje) {
      case 'es-MX':
        setMensajes(LangEspañol);
        setLocale('es-MX');
        break;
      case 'en-US':
        setMensajes(LangIngles);
        setLocale('en-US');
        break;
      default:
        setMensajes(LangIngles);
        setLocale('en-US');
    }
  };
  return (
    <langContext.Provider value={{ establecerLenguaje: establecerLenguaje, locale: locale }}>
      <IntlProvider locale={locale} messages={mensajes}>
        {children}
      </IntlProvider>
    </langContext.Provider>
  );
};

export { LangProvider, langContext };

// Pool Id - AWS Console -> Cognito User Pool -> General Settings
const COGNITO_POOL_ID = 'us-east-1_qzjXumVla';

// Pool Id - AWS Console -> Cognito User Pool -> General Settings ->  App Clients
const COGNITO_APP_ID = '2ot0m819qsr7b61q0lff6k0g1n';

// Pool Id - AWS Console -> Cognito User Pool -> App Integration -> Domain Name
// exclude https:// , e.g. mycustomdomain.auth.us-east-2.amazoncognito.com
const COGNITO_APP_DOMAIN = 'login-ech.auth.us-east-1.amazoncognito.com';

// Redirect URI that cognito redirects to after login
// Must be listed at:
// AWS Console -> Cognito User Pool -> App Integration -> App Client Settings -> Callback URLs
const COGNITO_REDIRECT_URI = 'https://mdedecisiones.ecloudapp.cloud/';
//const COGNITO_REDIRECT_URI = 'https://mdedecisiones.ecloudapp.cloud/' http://localhost:3000/ ;

// const COGNITO_REDIRECT_URI = 'https://myreactapp.com'

// Data that will be shared by 3rd party identity providers
// Must be defined at:
// AWS Console -> Cognito User Pool -> App Integration -> App Client Settings -> Allowed Scopes
const COGNITO_SCOPE = ['openid'];
//['mail', 'openid']

// AWS Console -> Cognito User Pool -> App Integration -> App Client Settings -> Callback URLs
const COGNITO_REDIRECT_LOGOUT_URI = `${COGNITO_APP_DOMAIN}/logout?response_type=code&client_id=${COGNITO_APP_ID}&redirect_uri=${COGNITO_REDIRECT_URI}&scope=${COGNITO_SCOPE}+profile`;

// Identity Providers Being Used
// Currently just set to 1, need to look into adding multiple identity providers
// Enable identity provider at:
// AWS Console -> Cognito User Pool -> Federation -> Identity Providers
// AWS Console -> Cognito User Pool -> Federation -> Attribute Mapping
// AWS Console -> Cognito User Pool -> App Integration -> App Client Settings -> Enabled Providers
const COGNITO_ID_PROVIDER = 'Google';

// Cognito URL for identity provider authenticaton (e.g. Google)
// Pointing browser to this url will cause cognito to redirect
// to identity provider for authentication purposes
// identify provider returns credentials to cognito
// cognito then returns auth code to app as a url param
const COGNITO_ID_PROVIDER_URL = `https://${COGNITO_APP_DOMAIN}/oauth2/authorize?redirect_uri=${COGNITO_REDIRECT_URI}&response_type=code&client_id=${COGNITO_APP_ID}&identity_provider=${COGNITO_ID_PROVIDER}`;

//Cognito URL for Login provider
const COGNITO_URL_LOGIN_CUSTOM = `https://${COGNITO_APP_DOMAIN}/login?client_id=${COGNITO_APP_ID}&response_type=token&scope=${COGNITO_SCOPE}+profile+aws.cognito.signin.user.admin&redirect_uri=${COGNITO_REDIRECT_URI}`;

export default {
  COGNITO_POOL_ID,
  COGNITO_APP_ID,
  COGNITO_APP_DOMAIN,
  COGNITO_REDIRECT_URI,
  COGNITO_SCOPE,
  COGNITO_ID_PROVIDER,
  COGNITO_ID_PROVIDER_URL,
  COGNITO_REDIRECT_LOGOUT_URI,
  COGNITO_URL_LOGIN_CUSTOM
};

import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

//Route Guard
import RouteGuard from 'Aouth/RouteGuard';

//RUTAS DASHBOARD
const TestingData = Loadable(lazy(() => import('views/dashboard/testingData')));
const MyHome = Loadable(lazy(() => import('views/dashboard/MyHome/index')));
const MyRiskManager = Loadable(lazy(() => import('views/dashboard/myRiskManager/index')));
const RiskTier = Loadable(lazy(() => import('views/dashboard/RiskTier/index')));
const AnswerTreeBusiness = Loadable(lazy(() => import('views/dashboard/AnswerTreeBusiness/index')));
const Warning = Loadable(lazy(() => import('views/dashboard/WarningGenerator/index')));
const VariablesWorkshop = Loadable(lazy(() => import('views/dashboard/VariablesWorkshop/index')));
const RatingGenerator = Loadable(lazy(() => import('views/dashboard/RatingGenerator/index')));
const UsageStatistics = Loadable(lazy(() => import('views/dashboard/UsageStatics/index')));
const BusinessRatios = Loadable(lazy(() => import('views/dashboard/BusinessRatios/index')));
const FinancialRatios = Loadable(lazy(() => import('views/dashboard/FinancialRatios/index')));
const ModelDeployment = Loadable(lazy(() => import('views/dashboard/ModelDeployement/index')));
const EngineOptimizer = Loadable(lazy(() => import('views/dashboard/EngineOptimizer/index')));
const EjecutorScripts = Loadable(lazy(() => import('views/dashboard/EjecutorScript/index')));
// const EjecutorPrompts = Loadable(lazy(() => import('views/dashboard/EjecutorPrompt/index')));
const OnLineData = Loadable(lazy(() => import('views/dashboard/onLineData/')));
const OnLineDataHistorico = Loadable(lazy(() => import('views/dashboard/OnLineDataHistorico')));
const MonitorLogs = Loadable(lazy(() => import('views/dashboard/MonitorLogs/')));
const DataEntryEditor = Loadable(lazy(() => import('views/dashboard/DataEntryEditor/')));
const DataExit = Loadable(lazy(() => import('views/dashboard/DataExit/index')));
const MisStatisticals = Loadable(lazy(() => import('views/dashboard/MisStatistical/')));
const Programming = Loadable(lazy(() => import('views/dashboard/ProgrammingChampionChallenger')));
// const ConnectionWeb = Loadable(lazy(() => import('views/dashboard/ConnectionWeb/')));
const ConnectionHBES = Loadable(lazy(() => import('views/dashboard/ConnectionHBES/')));
const SystemOptions = Loadable(lazy(() => import('views/dashboard/SystemOptions/')));
const BlackList = Loadable(lazy(() => import('views/dashboard/BlackList/')));
const GreenList = Loadable(lazy(() => import('views/dashboard/GreenList/')));
const Profiles = Loadable(lazy(() => import('views/dashboard/Profiles/')));
const Users = Loadable(lazy(() => import('views/dashboard/Users/')));
const UsersWorkflowApproval = Loadable(lazy(() => import('views/dashboard/UserWorkflowApproval/')));
const MRMVisualSettings = Loadable(lazy(() => import('views/dashboard/MRMvisualSettings/')));
const MRMController = Loadable(lazy(() => import('views/dashboard/MRMcontroller/')));
// const MainRoute = Loadable(lazy(() => import('views/dashboard/Default')));
const ModuloIA = Loadable(lazy(() => import('views/dashboard/ModuloIA/index')));

//utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

//sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <RouteGuard>
      <MainLayout />
    </RouteGuard>
  ),
  children: [
    {
      path: '/',
      element: <MyHome />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <MyHome />
        }
      ]
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'testing-data',
          element: <TestingData />
        }
      ]
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'my-home',
          element: <MyHome />
        }
      ]
    },

    {
      path: 'dashboard',
      children: [
        {
          path: 'my-risk-manager',
          element: <MyRiskManager />
        }
      ]
    },

    {
      path: 'dashboard',
      children: [
        {
          path: 'risk-tier',
          element: <RiskTier />
        }
      ]
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'answer-tree-business',
          element: <AnswerTreeBusiness />
        }
      ]
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'warning-generator',
          element: <Warning />
        }
      ]
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'variables-workshop',
          element: <VariablesWorkshop />
        }
      ]
    },

    {
      path: 'dashboard',
      children: [
        {
          path: 'rating-generator',
          element: <RatingGenerator />
        }
      ]
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'usage-statistics',
          element: <UsageStatistics />
        }
      ]
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'bussiness-ratios',
          element: <BusinessRatios />
        }
      ]
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'financial-ratios',
          element: <FinancialRatios />
        }
      ]
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'model-deployement',
          element: <ModelDeployment />
        }
      ]
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'engine-optimizer',
          element: <EngineOptimizer />
        }
      ]
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'ejecutor-script',
          element: <EjecutorScripts />
        }
      ]
    },
    // {
    //   path: 'dashboard',
    //   children: [
    //     {
    //       path: 'ejecutor-prompt',
    //       element: <EjecutorPrompts />
    //     }
    //   ]
    // },
    {
      path: 'dashboard',
      children: [
        {
          path: 'on-line-data',
          element: <OnLineData />
        }
      ]
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'on-line-data-historico',
          element: <OnLineDataHistorico />
        }
      ]
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'monitor-logs',
          element: <MonitorLogs />
        }
      ]
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'data-entry-editor',
          element: <DataEntryEditor />
        }
      ]
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'data-exit',
          element: <DataExit />
        }
      ]
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'modulo-ia',
          element: <ModuloIA />
        }
      ]
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'mis-statistical',
          element: <MisStatisticals />
        }
      ]
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'programming',
          element: <Programming />
        }
      ]
    },
    // {
    //   path: 'dashboard',
    //   children: [
    //     {
    //       path: 'connection-web',
    //       element: <ConnectionWeb />
    //     }
    //   ]
    // },
    {
      path: 'dashboard',
      children: [
        {
          path: 'connection-hbes',
          element: <ConnectionHBES />
        }
      ]
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'system-options',
          element: <SystemOptions />
        }
      ]
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'black-list',
          element: <BlackList />
        }
      ]
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'green-list',
          element: <GreenList />
        }
      ]
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'profiles',
          element: <Profiles />
        }
      ]
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'users',
          element: <Users />
        }
      ]
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'users-workflow-approval',
          element: <UsersWorkflowApproval />
        }
      ]
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'mrm-visual-settings',
          element: <MRMVisualSettings />
        }
      ]
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'mrm-controller',
          element: <MRMController />
        }
      ]
    },

    /////////////Seccion Utils/////////////////////////
    {
      path: 'utils',
      children: [
        {
          path: 'util-typography',
          element: <UtilsTypography />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-color',
          element: <UtilsColor />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-shadow',
          element: <UtilsShadow />
        }
      ]
    },
    {
      path: 'icons',
      children: [
        {
          path: 'tabler-icons',
          element: <UtilsTablerIcons />
        }
      ]
    },
    {
      path: 'icons',
      children: [
        {
          path: 'material-icons',
          element: <UtilsMaterialIcons />
        }
      ]
    },
    {
      path: 'sample-page',
      element: <SamplePage />
    }
  ]
};

export default MainRoutes;

import React, { useState } from 'react';
import {
  pais,
  industrias,
  TipoDocumento,
  OrigenVariableInformacion,
  OrigenVariableDatos,
  DataDealernet,
  DataEquifax,
  DataFuenteTerceros,
  DataPrevired,
  DataSiiActComercial,
  DataSiisa,
  DataSinacofi,
  DataEntry,
  DataTransunion,
  DataTruora,
  DataFinancial,
  DataPrompt,
  DataRating,
  DataScript,
  DataVariableWorkShop,
  Operador,
  Valoracion,
  Automotriz,
  Banco,
  seguroCredito,
  Financiera
} from './data';
import { useEffect } from 'react';
const ContextGlobal = React.createContext({});

const GlobalProvider = ({ children }) => {
  const [globalData, setGlobalData] = useState({});
  const [industria, setIndustria] = useState('Automotriz');
  const [templateTestingData, setTemplateTestingData] = useState(Automotriz);
  const [nameTemplateTesting, setNameTemplateTesting] = useState('Automotriz');

  //========|| Setear los datos de las opciones ||==========//
  useEffect(() => {
    setGlobalData({
      pais: pais,
      industrias: industrias,
      TipoDocumento: TipoDocumento,
      OrigenVariableInformacion: OrigenVariableInformacion,
      OrigenVariableDatos: OrigenVariableDatos,
      DataDealernet: DataDealernet,
      DataEquifax: DataEquifax,
      DataFuenteTerceros: DataFuenteTerceros,
      DataPrevired: DataPrevired,
      DataSiiActComercial: DataSiiActComercial,
      DataSiisa: DataSiisa,
      DataSinacofi: DataSinacofi,
      DataEntry: DataEntry,
      DataTransunion: DataTransunion,
      DataTruora: DataTruora,
      DataFinancial: DataFinancial,
      DataPrompt: DataPrompt,
      DataRating: DataRating,
      DataScript: DataScript,
      DataVariableWorkShop: DataVariableWorkShop,
      Operador: Operador,
      Valoracion: Valoracion,
      Automotriz: Automotriz,
      Banco: Banco,
      seguroCredito: seguroCredito,
      Financiera: Financiera
    });
  }, []);

  //==========|| setear la opcion escogida en sistem option ||========//
  const handleChangeIndustria = (value) => {
    if (value !== '') {
      switch (value) {
        case 'Automotriz':
          setTemplateTestingData(Automotriz);
          setNameTemplateTesting('Automotriz');
          setIndustria(value);
          break;
        case 'Banco':
          setTemplateTestingData(Banco);
          setNameTemplateTesting('Banco');
          setIndustria(value);
          break;
        case 'Seguro de crédito':
          setTemplateTestingData(seguroCredito);
          setNameTemplateTesting('Seguro de Credito');
          setIndustria(value);
          break;
        case 'Financiera':
          setTemplateTestingData(Financiera);
          setNameTemplateTesting('Financiera');
          setIndustria(value);
          break;
      }
    }
  };
  console.log('templateTestingData:', templateTestingData);
  return (
    <ContextGlobal.Provider
      value={{
        globalData: globalData,
        industria: industria,
        templateTestingData: templateTestingData,
        nameTemplateTesting: nameTemplateTesting,
        handleChangeIndustria: handleChangeIndustria
      }}
    >
      {children}
    </ContextGlobal.Provider>
  );
};
export { ContextGlobal, GlobalProvider };

const industrias = ['Automotriz', 'Seguro de crédito', 'Financiera', 'Banco'];

const pais = [
  'Chile',
  'Colombia',
  'Perú',
  'Argentina',
  'España',
  'Bolivia',
  'Costa rica',
  'Ecuador',
  'Nicaragua',
  'Uruguay',
  'Venezuela',
  'Brasil',
  'El Salvador',
  'Guatemala',
  'Honduras',
  'Mexico',
  'Panama',
  'Paraguay',
  'Portugal',
  'Republica Dominicana'
];
const TipoDocumento = ['Natural', 'Juridico'];

const OrigenVariableDatos = [
  'DEALERNET',
  'EQUIFAX',
  'FUENTE DE TERCEROS',
  'PREVIRED',
  'SII ACT. COMERCIAL',
  'SIISA',
  'SINACOFI',
  'DATA ENTRY',
  'TRANSUNION',
  'TRUORA'
];

const OrigenVariableInformacion = ['FINANCIAL RATIOS', 'PROMPT', 'VARIABLES WORKSHOP', 'RATING', 'SCRIPT'];

const DataDealernet = ['informe Deuda [ARRAY]'];
const DataEquifax = [
  'Acrcreditations/AccreditationsFlag[TEXTO]',
  'Activity/ActivityOfTheEmployer[TEXTO]',
  'AddressesType/City',
  'AddressesType/Communes',
  'AddressesType/Region'
];
const DataFuenteTerceros = [
  'Boletin Concursal-N° Documentos [NÚMERO]',
  'Causas[ARRAY]',
  'OFAC-N° Documentos[NÚMERO]',
  'Poder Judicial-N° Publicaciones[NÚMERO]',
  'Score[NÚMERO]'
];

const DataPrevired = [
  'Apellido Materno Cliente[TEXTO]',
  'Apellido Paterno Cliente[TEXTO]',
  'Fecha Consulta[FECHA]',
  'Fecha de Pago Cotizaciones [FECHA]',
  'Firma Documento Emitido [TEXTO]',
  'Folio Documento Emitido [NÚMERO]',
  'Hota Consulta [TEXTO]',
  'Mes y año de la Cotización [FECHA]',
  'Monto de Cotización [NÚMERO]',
  'Nombre AFP[TEXTO]',
  'Nombre Cliente [TEXTO]',
  'Remuneración Imposible [NÚMERO]',
  'Renta[NÚMERO]',
  'Rut Cliente[NÚMERO]',
  'Rut Empleador[TEXTO]',
  'Tipo Movimiento[TEXTO]'
];
const DataSiiActComercial = ['Fecha inicio actividades[FECHA]'];
const DataSiisa = ['Morosidad incofom - Monto', 'Morosidad incofom - Nombre', 'Morosidad industria y Comercio -Codigo'];
const DataSinacofi = ['Bancos Rel [TEXTO]', 'Bienes-Avaluo', 'Bienes-cantidad', 'Boletines-Boletin', 'Boletines-Fecha'];
const DataEntry = ['Moneda[TEXTO]', 'Monto[NÚMERO]', 'Plazo[NÚMERO]'];
const DataTransunion = [
  'Acumulacion de documentos[TEXTO]',
  'Acumulación de montos en periodo mayor 24[TEXTO]',
  'año actual[TEXTO]',
  'Año anterior[TEXTO]'
];
const DataTruora = ['check_id[TEXTO]', 'Poder judicial Causas Civiles-Detalles de el caso-Descripcion[TEXTO]'];
const DataFinancial = ['Financial Ratios'];
const DataPrompt = ['Prueba[TEXTO]'];
const DataRating = ['Clasificación [TEXTO]'];
const DataScript = ['Apalancamiento[NÚMERO]', 'Cálculo de gastos[NÚMERO]'];
const DataVariableWorkShop = [''];
const Operador = ['<', '>', '=', '!=', '>=', '<='];
const Valoracion = ['Muy malo', 'Malo', 'Regular', 'Bueno', 'Muy bueno'];
const Automotriz = [
  {
    titulo: 'Tipo de Documento',
    type: 'text',
    name: 'tipoDocumento',
    value: '',
    datos: ['DNI', 'RUT']
  },
  {
    titulo: 'N° Documento',
    type: 'number',
    name: 'numDocumento',
    value: '',
    datos: []
  },
  {
    titulo: 'Monto',
    type: 'number',
    name: 'monto',
    value: '',
    datos: []
  },
  {
    titulo: 'Monto pie',
    type: 'number',
    name: 'montoPie',
    value: '',
    datos: []
  },
  {
    titulo: 'Saldo precio',
    type: 'number',
    name: 'saldoPrecio',
    value: '',
    datos: []
  },
  {
    titulo: 'Pie %',
    type: 'number',
    name: 'pie',
    value: '',
    datos: []
  },
  {
    titulo: 'Plazo',
    type: 'number',
    name: 'plazo',
    value: '',
    datos: []
  },
  {
    titulo: 'Cuota',
    type: 'number',
    name: 'cuota',
    value: '',
    datos: []
  },
  {
    titulo: 'Edad',
    type: 'number',
    name: 'edad',
    value: '',
    datos: []
  },
  {
    titulo: 'Nacionalidad',
    type: 'text',
    name: 'nacionalidad',
    value: '',
    datos: ['Chileno', 'Peruano', 'Uruguayo', 'Mexicano']
  },
  {
    titulo: 'Tipo de Trabajador',
    type: 'text',
    name: 'tipoTrabajador',
    value: '',
    datos: ['Dependiente', 'Independiente', 'Jubilado']
  },
  {
    titulo: 'Antigüedad laboral en meses',
    type: 'number',
    name: 'antiguedad',
    value: '',
    datos: []
  },
  {
    titulo: 'Vehiculo Chino',
    type: 'text',
    name: 'vehiculoChino',
    value: '',
    datos: ['Si', 'No']
  },
  {
    titulo: 'Antigüedad vehiculo en años',
    type: 'number',
    name: 'antiguedadVehiculo',
    value: '',
    datos: []
  },
  {
    titulo: 'Producto',
    type: 'text',
    name: 'producto',
    value: '',
    datos: ['Inmediato', 'Recurrente']
  },
  {
    titulo: 'Ingreso Líquido',
    type: 'number',
    name: 'ingresoLiquido',
    value: '',
    datos: []
  },
  {
    titulo: 'Honorarios',
    type: 'number',
    name: 'honorarios',
    value: '',
    datos: []
  },
  {
    titulo: 'Pensión Jubilado',
    type: 'number',
    name: 'pensionJubilado',
    value: '',
    datos: []
  },
  {
    titulo: 'Otros Ingresos',
    type: 'number',
    name: 'otrosIngresos',
    value: '',
    datos: []
  },
  {
    titulo: 'Auto nuevo',
    type: 'text',
    name: 'autoNuevo',
    value: '',
    datos: ['Si', 'No']
  },
  {
    titulo: 'Canal',
    type: 'text',
    name: 'canal',
    value: '',
    datos: ['America', 'Europa', 'Asia']
  }
];

const Banco = [
  {
    titulo: 'Tipo de Documento',
    type: 'text',
    name: 'tipoDocumento',
    value: '',
    datos: ['DNI', 'RUT']
  },
  {
    titulo: 'N° Documento',
    type: 'number',
    name: 'numDocumento',
    value: '',
    datos: []
  },
  {
    titulo: 'Monto',
    type: 'number',
    name: 'monto',
    value: '',
    datos: []
  },
  {
    titulo: 'Monto pie',
    type: 'number',
    name: 'montoPie',
    value: '',
    datos: []
  },
  {
    titulo: 'Saldo precio',
    type: 'number',
    name: 'saldoPrecio',
    value: '',
    datos: []
  },
  {
    titulo: 'Pie %',
    type: 'number',
    name: 'pie',
    value: '',
    datos: []
  },
  {
    titulo: 'Plazo',
    type: 'number',
    name: 'plazo',
    value: '',
    datos: []
  },
  {
    titulo: 'Cuota',
    type: 'number',
    name: 'cuota',
    value: '',
    datos: []
  },
  {
    titulo: 'Edad',
    type: 'number',
    name: 'edad',
    value: '',
    datos: []
  },
  {
    titulo: 'Nacionalidad',
    type: 'text',
    name: 'nacionalidad',
    value: '',
    datos: ['Chileno', 'Peruano', 'Uruguayo', 'Mexicano']
  }
];

const seguroCredito = [
  {
    titulo: 'Tipo de Documento',
    type: 'text',
    name: 'tipoDocumento',
    value: '',
    datos: ['DNI', 'RUT']
  },
  {
    titulo: 'N° Documento',
    type: 'number',
    name: 'numDocumento',
    value: '',
    datos: []
  },
  {
    titulo: 'Monto',
    type: 'number',
    name: 'monto',
    value: '',
    datos: []
  },
  {
    titulo: 'Monto pie',
    type: 'number',
    name: 'montoPie',
    value: '',
    datos: []
  },
  {
    titulo: 'Saldo precio',
    type: 'number',
    name: 'saldoPrecio',
    value: '',
    datos: []
  },
  {
    titulo: 'Pie %',
    type: 'number',
    name: 'pie',
    value: '',
    datos: []
  },
  {
    titulo: 'Plazo',
    type: 'number',
    name: 'plazo',
    value: '',
    datos: []
  },
  {
    titulo: 'Cuota',
    type: 'number',
    name: 'cuota',
    value: '',
    datos: []
  },
  {
    titulo: 'Edad',
    type: 'number',
    name: 'edad',
    value: '',
    datos: []
  },
  {
    titulo: 'Nacionalidad',
    type: 'text',
    name: 'nacionalidad',
    value: '',
    datos: ['Chileno', 'Peruano', 'Uruguayo', 'Mexicano']
  },
  {
    titulo: 'Tipo de Trabajador',
    type: 'text',
    name: 'tipoTrabajador',
    value: '',
    datos: ['Dependiente', 'Independiente', 'Jubilado']
  },
  {
    titulo: 'Antigüedad laboral en meses',
    type: 'number',
    name: 'antiguedad',
    value: '',
    datos: []
  },

  {
    titulo: 'Producto',
    type: 'text',
    name: 'producto',
    value: '',
    datos: ['Inmediato', 'Recurrente']
  },
  {
    titulo: 'Ingreso Líquido',
    type: 'number',
    name: 'ingresoLiquido',
    value: '',
    datos: []
  },
  {
    titulo: 'Honorarios',
    type: 'number',
    name: 'honorarios',
    value: '',
    datos: []
  },
  {
    titulo: 'Pensión Jubilado',
    type: 'number',
    name: 'pensionJubilado',
    value: '',
    datos: []
  },
  {
    titulo: 'Otros Ingresos',
    type: 'number',
    name: 'otrosIngresos',
    value: '',
    datos: []
  },

  {
    titulo: 'Canal',
    type: 'text',
    name: 'canal',
    value: '',
    datos: ['America', 'Europa', 'Asia']
  }
];
const Financiera = [
  {
    titulo: 'Tipo de Documento',
    type: 'text',
    name: 'tipoDocumento',
    value: '',
    datos: ['DNI', 'RUT']
  },
  {
    titulo: 'N° Documento',
    type: 'number',
    name: 'numDocumento',
    value: '',
    datos: []
  },
  {
    titulo: 'Monto',
    type: 'number',
    name: 'monto',
    value: '',
    datos: []
  },
  {
    titulo: 'Monto pie',
    type: 'number',
    name: 'montoPie',
    value: '',
    datos: []
  },
  {
    titulo: 'Saldo precio',
    type: 'number',
    name: 'saldoPrecio',
    value: '',
    datos: []
  },
  {
    titulo: 'Pie %',
    type: 'number',
    name: 'pie',
    value: '',
    datos: []
  },
  {
    titulo: 'Plazo',
    type: 'number',
    name: 'plazo',
    value: '',
    datos: []
  },
  {
    titulo: 'Cuota',
    type: 'number',
    name: 'cuota',
    value: '',
    datos: []
  },
  {
    titulo: 'Edad',
    type: 'number',
    name: 'edad',
    value: '',
    datos: []
  },
  {
    titulo: 'Nacionalidad',
    type: 'text',
    name: 'nacionalidad',
    value: '',
    datos: ['Chileno', 'Peruano', 'Uruguayo', 'Mexicano']
  },
  {
    titulo: 'Tipo de Trabajador',
    type: 'text',
    name: 'tipoTrabajador',
    value: '',
    datos: ['Dependiente', 'Independiente', 'Jubilado']
  },
  {
    titulo: 'Antigüedad laboral en meses',
    type: 'number',
    name: 'antiguedad',
    value: '',
    datos: []
  }
];
export {
  pais,
  industrias,
  TipoDocumento,
  OrigenVariableInformacion,
  OrigenVariableDatos,
  DataDealernet,
  DataEquifax,
  DataFuenteTerceros,
  DataPrevired,
  DataSiiActComercial,
  DataSiisa,
  DataSinacofi,
  DataEntry,
  DataTransunion,
  DataTruora,
  DataFinancial,
  DataPrompt,
  DataRating,
  DataScript,
  DataVariableWorkShop,
  Operador,
  Valoracion,
  Automotriz,
  Banco,
  seguroCredito,
  Financiera
};

import React, { useContext } from 'react';
// import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'AuthContext/AuthContext';
//import { useNavigate } from 'react-router-dom';
import { Grid, CircularProgress } from '@mui/material';
import Titulo from 'ui-component/Titulo/Titulo';

function RouteGuard({ children }) {
  //const navigates = useNavigate();
  // const navigate = useNavigate();
  const { LoginUrl, ComprobarLocalStorage, authentication } = useContext(AuthContext);

  ComprobarLocalStorage();
  LoginUrl();

  //console.log('loading : ', authentication);
  if (!authentication) {
    return (
      <>
        <Grid container direction="column" justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
          <Grid item>
            <CircularProgress />
          </Grid>
          <Grid>
            <Titulo titulo="Cargando " fontSize="30px" />
          </Grid>
        </Grid>
      </>
    );
  }

  return children;
}

export default RouteGuard;

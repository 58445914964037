import React, { createContext, useState } from 'react';
// import * as auth from '../Aouth/Aouth.js';
// import { useLocation } from 'react-router';
// import axios from 'axios';
// import url from 'baseUrl.js';
import { propTypes } from 'react-bootstrap/esm/Image';
import Config from 'configJs';
// import { string } from 'prop-types';

//clave local storage
//const key = 'user';
//const keySub = 'sub';
const keyToken = 'accesToken';
const AuthContext = createContext();

function AuthProvider({ children }) {
  const [authentication, setAuthentication] = useState(false);
  //const [expiredToken, setExpiredToken] = useState(false);
  //const [isLoadingExpired, setIsLoadingExpired] = React.useState(false);
  //const [isLoading, setIsLoading] = useState(true);
  // const [loadingAuthVerify, setLoadingAuthVerify] = React.useState(false);
  //const [user, setUser] = useState(null);
  //const [dataUser, setDataUser] = useState('');
  //const [idUser, setIdUser] = useState('');
  //const [userCognito, setUserCognito] = useState(false);
  //const [sub, setSub] = useState('');
  //console.log('user in context : ', user);
  //const location = useLocation();
  const searchParams = new URLSearchParams(location.hash);
  const accesToken = searchParams.get('access_token');
  //const idToken = searchParams.get('#id_token');
  //const expires = searchParams.get('expires_in');
  //const tokenType = searchParams.get('token_type');
  //console.log('location:', location);
  //console.log('search :', searchParams);
  //console.log('id_token :', idToken);
  //console.log('access_token :', accesToken);
  //console.log('Expira en :', expires);
  //console.log('token_type', tokenType);
  // const URI = '/login/process-token';
  // const URLCOGNITO = 'https://loginech.auth.us-east-1.amazoncognito.com/oauth2/userInfo';
  // const URIGETUSER = '/login/get-current-user';

  //===================|| Obtener token y enviarlo al back ||==================//
  // const login = React.useCallback(async function () {
  //   if (accesToken != null) {
  //     console.log('si hay eccess token en url');
  //     const accesToken_send = { access_token: accesToken };
  //     console.log('datos enviados :', accesToken_send);
  //     axios
  //       .post(`${url.BASE_URL}${URI}`, accesToken_send)
  //       .then((res) => {
  //         const respuestaServidor = res.data;
  //         console.log('respuesta de servidor :', respuestaServidor);
  //         console.log('decoded_string token :', JSON.stringify(respuestaServidor));

  //         const sub_data = respuestaServidor.sub;
  //         console.log('sub :', sub_data);
  //         localStorage.setItem(keySub, JSON.stringify(sub_data));
  //         setSub(sub);
  //         setAuthentication(true);
  //       })
  //       .catch((err) => {
  //         console.error({ message: 'error al traer datos al servidor', error: err });
  //       });
  //   } else if (await JSON.parse(localStorage.getItem(key))) {
  //     setAuthentication(true);
  //     console.log('ya se tiene un token : ', JSON.parse(localStorage.getItem(key)));
  //   } else {
  //     console.log('no hay ni token ni acces token');

  //     setAuthentication(false);
  //     //logout();
  //   }
  // }, []);

  //=================================|| Funciones para probar login nuevo ||========================//
  // Comprobar local storage
  const ComprobarLocalStorage = () => {
    const acces_Token = localStorage.getItem(keyToken);
    console.log('localsotre token:;', acces_Token);
    if (acces_Token) {
      setAuthentication(true);
    }
    if (!acces_Token) {
      setAuthentication(false);
    }
  };

  //comprobar login
  const LoginUrl = async () => {
    //console.log('autenticacion:', authentication);
    if (!authentication) {
      if (accesToken) {
        localStorage.setItem(keyToken, JSON.stringify(accesToken));
        console.log('llegamos a guardar el accestoken');
        setAuthentication(true);
        // setLoadingAuthVerify(true);
      } else {
        logout();
      }
    }
    return;
  };
  //
  //==================================|| Obtener datos usuarios desde cognito ||=============================//
  // const getUserData = () => {
  //   if (accesToken) {
  //     axios
  //       .post(URLCOGNITO, null, {
  //         headers: {
  //           Authorization: `Bearer ${accesToken}`,
  //           'Content-Type': 'application/json; charset=utf-8'
  //         }
  //       })
  //       .then((res) => {
  //         console.log(res.data);
  //         setUser(res.data);
  //         setDataUser(res.data);
  //         setUserCognito(true);
  //       })
  //       .catch((err) => {
  //         console.error({
  //           message: 'Error al procesa la solicitud con cognito',
  //           Error: string(err),
  //           detalle: err
  //         });
  //         setDataUser(null);
  //         setUserCognito(false);
  //       });
  //   } else {
  //     console.log('no hay accesToken');
  //   }
  // };

  // React.useEffect(() => {
  //   getUserData();
  // }, []);

  //================================|| Obtener ID User ||====================================//
  // const getIdUser = () => {
  //   const userData = { sub: JSON.parse(localStorage.getItem(keySub)) };
  //   console.log('id del usuario :', userData);
  //   if (userData != null) {
  //     axios
  //       .post(`${url.BASE_URL}${URIGETUSER}`, userData)
  //       .then((res) => {
  //         console.log('usuario en get id user :', res.data.id);
  //         setIdUser(res.data.id);
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //       });
  //   } else {
  //     setIdUser(null);
  //   }
  // };

  // React.useEffect(() => {
  //   getIdUser();
  // }, [localStorage.getItem(keySub)]);
  // console.log('userCognito :', userCognito);
  // console.log('data user from cognito :', dataUser);
  //================================|| GET TOKEN ||=========================//
  // React.useEffect(() => {
  //   login()
  //     .then(() => {
  //       setLoadingAuthVerify(false);
  //     })
  //     .catch(() => {
  //       setLoadingAuthVerify(false);
  //     });
  // }, []);
  //======================================|| Logout ||================================//
  const logout = React.useCallback(function () {
    localStorage.removeItem(keyToken);
    setAuthentication(false);
    window.location.href = Config.COGNITO_URL_LOGIN_CUSTOM;
  }, []);

  //==========================|| TIEMPO DE EXPIRACIÓN ||===============//
  // const getExpiredTime = React.useCallback(async () => {
  //   const expToken = await JSON.parse(localStorage.getItem(key));
  //   console.log('obteniendo expired :', expToken);
  //   if (expToken) {
  //     const expDate = new Date(expToken.exp * 1000);
  //     const now = new Date();
  //     console.log('exp en token :', expToken.exp, ' exDate:', expDate, 'now:', now);
  //     if (expDate < now.getTime()) {
  //       console.log('exp : ', expDate, 'tiempo actual :', now.getTime());
  //       setExpiredToken(true);
  //     } else {
  //       setExpiredToken(false);
  //     }
  //   } else if (!expToken) {
  //     setExpiredToken(false);
  //   }
  // }, []);
  //================================|| OBTENER EXPIRE TIME ||============================//
  // React.useEffect(() => {
  //   getExpiredTime()
  //     .then(() => setIsLoadingExpired(false))
  //     .catch(() => setIsLoadingExpired(false));
  // }, []);
  //================================|| OBTENER USER ||==========================//
  // React.useEffect(() => {
  //   getCurrentUser()
  //     .then(() => setIsLoading(false))
  //     .catch(() => setIsLoading(false));
  // }, []);

  const authValue = React.useMemo(
    () => ({
      authentication,
      //isLoading,
      logout,
      ComprobarLocalStorage,
      LoginUrl
    }),
    [authentication, logout, ComprobarLocalStorage, LoginUrl]
  );

  return <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>;
}

AuthProvider.prototype = {
  children: propTypes.object
};

export { AuthProvider, AuthContext };

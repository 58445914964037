// assets
import {
  IconClipboardData,
  IconHome,
  IconBinaryTree2,
  IconFileAnalytics,
  IconAlertTriangle,
  IconPercentage,
  IconBadges,
  IconChartBar,
  IconAdjustmentsHorizontal,
  IconCurrencyDollar,
  IconCalculator,
  IconSettings,
  IconPrompt,
  //IconCloudComputing,
  IconArticle,
  IconTerminal2,
  IconChartHistogram,
  //IconWifi,
  IconPlugConnected,
  IconTool,
  IconCode,
  IconAddressBook,
  IconUserCircle,
  IconFileLike,
  IconDeviceDesktop,
  IconDatabase,
  IconOutbound,
  IconLockAccess,
  IconCheckbox,
  IconBrain,
  IconFileAlert
} from '@tabler/icons';

import './styles.css';

// constant
const icons = {
  IconClipboardData,
  IconHome,
  IconBinaryTree2,
  IconAlertTriangle,
  IconFileAnalytics,
  IconPercentage,
  IconBadges,
  IconChartBar,
  IconAdjustmentsHorizontal,
  IconCurrencyDollar,
  IconCalculator,
  IconSettings,
  IconPrompt,
  //IconCloudComputing,
  IconArticle,
  IconTerminal2,
  IconChartHistogram,
  // IconWifi,
  IconPlugConnected,
  IconTool,
  IconCode,
  IconAddressBook,
  IconUserCircle,
  IconFileLike,
  IconDeviceDesktop,
  IconDatabase,
  IconOutbound,
  IconLockAccess,
  IconCheckbox,
  IconBrain,
  IconFileAlert
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'dashboard',
  title: '',
  type: 'group',
  children: [
    {
      id: 'testing-data',
      title: 'Testing Data',
      type: 'item',
      url: '/dashboard/testing-data',
      icon: icons.IconClipboardData,
      breadcrumbs: false
    },
    {
      id: 'my-home',
      title: 'My Home',
      type: 'item',
      url: '/dashboard/my-home',
      icon: icons.IconHome,
      breadcrumbs: false
    },
    {
      id: 'my-risk-manager',
      title: 'My Risk Manager',
      type: 'item',
      url: '/dashboard/my-risk-manager',
      icon: icons.IconFileAlert,
      breadcrumbs: false
    },
    {
      id: 'risk-tier',
      title: 'Risk Tier',
      type: 'item',
      url: '/dashboard/risk-tier',
      icon: icons.IconFileAnalytics,
      breadcrumbs: false
    },
    {
      id: 'answer-tree-business',
      title: 'Answer Tree Business',
      type: 'item',
      url: '/dashboard/answer-tree-business',
      icon: icons.IconBinaryTree2,
      breadcrumbs: false
    },
    {
      id: 'warning-generator',
      title: 'Warning Generator',
      type: 'item',
      url: '/dashboard/warning-generator',
      icon: icons.IconAlertTriangle,
      breadcrumbs: false
    },
    {
      id: 'variables_workshop',
      title: 'Variables Workshop',
      type: 'item',
      url: '/dashboard/variables-workshop',
      icon: icons.IconPercentage,
      breadcrumbs: false
    },
    {
      id: 'rating-generator',
      title: 'Rating Generator',
      type: 'item',
      url: '/dashboard/rating-generator',
      icon: icons.IconBadges,
      breadcrumbs: false
    },
    {
      id: 'usage-statistics',
      title: 'Usage Statistics',
      type: 'item',
      url: '/dashboard/usage-statistics',
      icon: icons.IconChartBar,
      breadcrumbs: false
    },

    {
      id: 'model-deployement',
      title: 'Model Deployement',
      type: 'item',
      url: '/dashboard/model-deployement',
      icon: icons.IconCalculator,
      breadcrumbs: false
    },
    {
      id: 'engine-optimizer',
      title: 'Engine Optimizer',
      type: 'item',
      url: '/dashboard/engine-optimizer',
      icon: icons.IconSettings,
      breadcrumbs: false
    },
    {
      id: 'ejecutor-script',
      title: 'Ejecutor Script',
      type: 'item',
      url: '/dashboard/ejecutor-script',
      icon: icons.IconCode,
      breadcrumbs: false
    },
    // {
    //   id: 'ejecutor-prompt',
    //   title: 'Ejecutor Prompt',
    //   type: 'item',
    //   url: '/dashboard/ejecutor-prompt',
    //   icon: icons.IconCloudComputing,
    //   breadcrumbs: false
    // },
    {
      id: 'on-line-data',
      title: 'On Line Data',
      type: 'item',
      url: '/dashboard/on-line-data',
      icon: icons.IconArticle,
      breadcrumbs: false
    },
    {
      id: 'on-line-data-historico',
      title: 'Historical Data',
      type: 'item',
      url: '/dashboard/on-line-data-historico',
      icon: icons.IconChartHistogram,
      breadcrumbs: false
    },
    {
      id: 'monitor-logs',
      title: 'Monitor Logs',
      type: 'item',
      url: '/dashboard/monitor-logs',
      icon: icons.IconTerminal2,
      breadcrumbs: false
    },

    {
      id: 'data-exit',
      title: 'Builder Testing Data',
      type: 'item',
      url: '/dashboard/data-exit',
      icon: icons.IconOutbound,
      breadcrumbs: false
    },
    {
      id: 'modulo-ia',
      title: 'Módulo IA',
      type: 'item',
      url: '/dashboard/modulo-ia',
      icon: icons.IconBrain,
      breadcrumbs: false
    },
    {
      id: 'programming',
      title: 'Programming Champion Challenger',
      type: 'item',
      url: '/dashboard/programming',
      icon: icons.IconTool,
      breadcrumbs: false
    },
    {
      id: 'mis-statistical',
      title: 'Champion/Challenger Review',
      type: 'item',
      url: '/dashboard/mis-statistical',
      icon: icons.IconChartHistogram,
      breadcrumbs: false
    },

    // {
    //   id: 'connection-web',
    //   title: 'Connection Web',
    //   type: 'item',
    //   url: '/dashboard/connection-web',
    //   icon: icons.IconWifi,
    //   breadcrumbs: false
    // },
    {
      id: 'connection-hbes',
      title: 'Connection HBES',
      type: 'item',
      url: '/dashboard/connection-hbes',
      icon: icons.IconPlugConnected,
      breadcrumbs: false
    },
    {
      id: 'system-options',
      title: 'System Options',
      type: 'item',
      url: '/dashboard/system-options',
      icon: icons.IconTool,
      breadcrumbs: false
    },
    {
      id: 'black-list',
      title: 'Black List',
      type: 'item',
      url: '/dashboard/black-list',
      icon: icons.IconLockAccess,
      breadcrumbs: false
    },
    {
      id: 'green-list',
      title: 'Green List',
      type: 'item',
      url: '/dashboard/green-list',
      icon: icons.IconCheckbox,
      breadcrumbs: false
    },
    {
      id: 'profiles',
      title: 'Profiles',
      type: 'item',
      url: '/dashboard/profiles',
      icon: icons.IconAddressBook,
      breadcrumbs: false
    },
    {
      id: 'users',
      title: 'Users',
      type: 'item',
      url: '/dashboard/users',
      icon: icons.IconUserCircle,
      breadcrumbs: false
    },
    {
      id: 'users-workflow-approval',
      title: 'Users Workflow Approval',
      type: 'item',
      url: '/dashboard/users-workflow-approval',
      icon: icons.IconFileLike,
      breadcrumbs: false
    },
    {
      id: 'mrm-visual-settings',
      title: 'MRM Visual Settings',
      type: 'item',
      url: '/dashboard/mrm-visual-settings',
      icon: icons.IconDeviceDesktop,
      breadcrumbs: false
    },
    {
      id: 'mrm-controller',
      title: 'MRM Controller',
      type: 'item',
      url: '/dashboard/mrm-controller',
      icon: icons.IconDatabase,
      breadcrumbs: false
    }
  ]
};

export default dashboard;

import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

//import Lenguajes
import { LangProvider } from 'langContext';

//withAuthenticator;
import { AuthProvider } from 'AuthContext/AuthContext.js';

//Context global
import { GlobalProvider } from 'views/dashboard/Context/context';

// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);

  return (
    <StyledEngineProvider injectFirst>
      <LangProvider>
        <GlobalProvider>
          <ThemeProvider theme={themes(customization)}>
            <CssBaseline />
            <NavigationScroll>
              <AuthProvider>
                <Routes />
              </AuthProvider>
            </NavigationScroll>
          </ThemeProvider>
        </GlobalProvider>
      </LangProvider>
    </StyledEngineProvider>
  );
};

export default App;
